<template>
    <header class="page-title">
        <h1><i class="bi bi-bar-chart-fill"></i> レポート - アポ転換率推移</h1>
    </header>

    <section class="section">
        <div class="row align-items-end">
            <div class="col-6">
                <label class="form-label">事業部</label>
                <form-select
                    v-model="condition.department_id"
                    :options="options_department"
                    empty_option="全て"
                ></form-select>
            </div>
            <div class="col-6">
                <button @click="search()" :disabled="loading_count > 0" class="btn btn-primary">表示</button>
            </div>
        </div>
    </section>

    <section class="section">

        <h2>HOT</h2>
        <inline-loader v-if="loading || loading_hot" style="margin-bottom:calc(30% - 1rem);"></inline-loader>
        <combo-graph v-else :width="2000" :height="600" :chart="chart_hot"></combo-graph>

        <h2>COLD</h2>
        <inline-loader v-if="loading || loading_cold" style="margin-bottom:calc(30% - 1rem);"></inline-loader>
        <combo-graph v-else :width="2000" :height="600" :chart="chart_cold"></combo-graph>

        <p class="mt-3">反響数は、反響日が対象期間内のHOTのみ、アポ数は、アポ取得日が対象期間内の初回アポのみをカウントしています。<br>
        事業部を選択した場合、現時点の事業部で絞り込まれます。<br>
        クズ・お断り・重複（カウントしない）を除きます。</p>
    </section>

</template>

<script>
import ComboGraph from '@/components/reports/ComboGraph';
import FormSelect from '@/components/forms/FormSelect';
import InlineLoader from '@/components/tools/InlineLoader.vue';
import Department from '@/models/entities/department';
import Temperature from '@/models/enums/temperature';

export default {
    name: 'PageAppointmentRateTransition',
    components: {
        ComboGraph,
        InlineLoader,
        FormSelect,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showErrorMessage',
        'showMessage',
    ],
    data() {
        return {
            // ローディング
            loading: 0,
            loading_hot: 0,
            loading_cold: 0,

            // 検索フォーム
            options_department: [],

            // 検索条件
            condition: {
                department_id: null,
            },

            // 表示データ
            stats_hot: [],
            stats_cold: [],
        }
    },
    mounted() {
        this.fetchDepartments();
    },
    methods: {
        fetchDepartments() {
            this.loading++;

            // 事業部マスタ取得
            this.$http.get('/master/department')
            .then(response => {
                for (let row of response.data) {
                    let department = new Department(row);
                    this.options_department.push({value: department.department_id, label: department.department_name});
                }

                // 事業部初期値セット
                if (this.$store.state.auth.department_id) {
                    this.condition.department_id = this.$store.state.auth.department_id;
                } else {
                    this.condition.department_id = this.options_department[0].value;
                }

                this.search();
            })
            .finally(() => {
                //ローディング終了
                this.loading--;
            })
        },
        // 検索
        search() {
            // ローディング開始
            this.loading_hot++;
            this.loading_cold++;
            // 表示データ初期化
            this.stats_hot.splice(0);
            this.stats_cold.splice(0);

            this.$http.get('/report/appointment-rate-transition', {
                params: {
                    department_id: this.condition.department_id,
                    temperature: Temperature.HOT,
                }
            })
            .then(response => {
                this.stats_hot = response.data;
            })
            .finally(() => {
                // ローディング終了
                // this.loading_hot--;
                this.$nextTick(() => this.loading_hot--);
            });

            this.$http.get('/report/appointment-rate-transition', {
                params: {
                    department_id: this.condition.department_id,
                    temperature: Temperature.COLD,
                }
            })
            .then(response => {
                this.stats_cold = response.data;
            })
            .finally(() => {
                // ローディング終了
                // this.loading_cold--;
                this.$nextTick(() => this.loading_cold--);
            });
        },
    },
    computed: {
        chart_hot() {
            const label = this.stats_hot.map(row => row.label);
            const value = [];
            value[0] = {
                label: '反響数',
                data: this.stats_hot.map(row => row.contact_count),
                backgroundColor: '#0072f0',
                order: 1
            };
            value[1] = {
                label: '新規アポ数',
                data: this.stats_hot.map(row => row.appointment_count),
                backgroundColor: '#00b6cb',
                order: 1
            };
            value[2] = {
                label: 'アポ転換率',
                data: this.stats_hot.map(row => row.appointment_rate),
                borderColor: '#f20196',
                backgroundColor: '#ff2fb0',
                type: 'line',
                order: 0,
                yAxisID: 'y2'
            };
            return {
                label, value
            };
        },
        chart_cold() {
            const label = this.stats_cold.map(row => row.label);
            const value = [];
            value[0] = {
                label: '反響数',
                data: this.stats_cold.map(row => row.contact_count),
                backgroundColor: '#0072f0',
                order: 1
            };
            value[1] = {
                label: '新規アポ数',
                data: this.stats_cold.map(row => row.appointment_count),
                backgroundColor: '#00b6cb',
                order: 1
            };
            value[2] = {
                label: 'アポ転換率',
                data: this.stats_cold.map(row => row.appointment_rate),
                borderColor: '#f20196',
                backgroundColor: '#ff2fb0',
                type: 'line',
                order: 0,
                yAxisID: 'y2'
            };
            return {
                label, value
            };
        }
    }
}
</script>
