<template>
    <Bar
        :chart-options="chartOptions"
        :chart-data="chartData"
        :width="width"
        :height="height"
    />
</template>

<script>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, LineController, BarElement, LineElement, PointElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, LineController, BarElement, LineElement, PointElement, CategoryScale, LinearScale);

export default {
    name: 'ComboGraph',
    components: { Bar },
    props: {
        width: {
            type: Number,
            default: 400
        },
        height: {
            type: Number,
            default: 400
        },
        chart: {
            type: Object,
            default: () => {}
        },
    },
    data() {
        return {
            chartData: {
                labels: this.chart.label,
                datasets: this.chart.value,
                /*
                labels: ['a', 'b', 'c'],
                datasets: [
                    {
                        label: 'Dataset 1',
                        data: [30, 40, 50],
                        backgroundColor: '#0072f0',
                        order: 1
                    },
                    {
                        label: 'Dataset 2',
                        data: [35, 45, 55],
                        backgroundColor: '#00b6cb',
                        order: 2
                    },
                    {
                        label: 'Dataset 2',
                        data: [35, 25, 35],
                        borderColor: '#f20196',
                        backgroundColor: '#ff2fb0',
                        type: 'line',
                        order: 0
                    }
                ]
                */
            },
            chartOptions: {
                responsive: true,
                barPercentage: 0.8,
                scales: {
                    y: {
                        type: 'linear',
                        display: true,
                        position: 'left',
                    },
                    y2: {
                        type: 'linear',
                        display: false,
                        position: 'right',
                    }
                }
            }
        }
    }
}
</script>